.s-contact-us__content-path {
  margin-bottom: 6rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}

.s-contact-us__content-path-icon {
  height: 1rem;
  width: 1rem;
}

.s-contact-us__content-path-text {
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}

.s-contact-us__content-path-text--transparent {
  opacity: 0.72;
}

.s-contact-us__wrapper-pre-content {
  margin-bottom: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.s-contact-us__wrapper-pre-content p {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}

.s-contact-us__pre-content {
  font-size: 1.5rem;
  line-height: 100%;
  letter-spacing: -0.02em;
}

.s-contact-us__schedule {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02em;
  opacity: 0.72;
}

.s-contact-us__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.s-contact-us__body>h2 {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}

.s-contact-us__body>div {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
}

.s-contact-us__contact-lists {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.s-contact-us__title {
  position: relative;
  font-size: 6rem;
  text-transform: uppercase;
  line-height: 100%;
  letter-spacing: 0.02em;
}

.s-contact-us__title::after {
  position: absolute;
  bottom: -2rem;
  left: 0px;
  height: 0.125rem;
  width: 15.9375rem;
  background-color: var(--commonBlack);
  --tw-content: "";
  content: var(--tw-content);
}

.s-contact-us__list-phone__container,
.s-contact-us__list-mail__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4rem;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0em;
}

.s-contact-us__list-phone__container {
  margin-bottom: 4rem;
}

.s-contact-us__list-mail__container {
  margin-bottom: 8rem;
}

.s-contact-us__list-phone li,
.s-contact-us__list-mail li {
  word-break: break-all;
}

.s-contact-us__button {
  margin-bottom: 8rem;
  max-width: 27.375rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.s-contact-us__list-soc__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  gap: 4rem;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0em;
}

.s-contact-us__list-soc {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  -moz-column-gap: 4rem;
  -webkit-column-gap: 4rem;
  column-gap: 4rem;
  row-gap: 1.5rem;
  font-family: InterRegular;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
}

@media (max-width: 79.9988em) {
  .s-contact-us__content-path {
    margin-bottom: 3rem;
  }

  .s-contact-us__body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 3.5rem;
  }
}

@media (max-width: 47.9988em) {
  .s-contact-us__content-path {
    display: none;
  }

  .s-contact-us__wrapper-pre-content {
    margin-bottom: 1.25rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .s-contact-us__wrapper-pre-content p {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
  }

  .s-contact-us__pre-content {
    font-family: InterMedium;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: 0em;
  }

  .s-contact-us__schedule {
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: 0em;
  }

  .s-contact-us__schedule>br {
    display: none;
  }

  .s-contact-us__title {
    font-size: 2.75rem;
    line-height: 3.5rem;
    letter-spacing: -0.01em;
  }

  .s-contact-us__title::after {
    bottom: -1.5rem;
    width: 4.875rem;
  }

  .s-contact-us__list-phone__container,
  .s-contact-us__list-mail__container {
    gap: 2rem;
    font-family: InterMedium;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.01em;
  }

  .s-contact-us__list-phone__container {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-bottom: 2rem;
  }

  .s-contact-us__list-mail__container {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin-bottom: 3.25rem;
  }

  .s-contact-us__list-phone li,
  .s-contact-us__list-mail li {
    font-size: 1.125rem;
  }

  .s-contact-us__button {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
    margin-bottom: 0px;
    max-width: 100%;
  }

  .s-contact-us__list-soc__container {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-bottom: 6.375rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 0.75rem;
    font-family: InterMedium;
    font-size: 0.875rem;
    line-height: 1.125rem;
  }

  .s-contact-us__list-soc {
    -moz-column-gap: 0.75rem;
    -webkit-column-gap: 0.75rem;
    column-gap: 0.75rem;
  }
}

@media (prefers-color-scheme: dark) {
  .s-contact-us {
    --commonBlack: #ffffff;
  }
}